<template>
  <div class="personalCenter animate__animated animate__fadeIn">
    <header>
      <Headers :header_data="[{title:'个人中心'}]"/>
    </header>
    <div class="main">
      <div class="tabs">
        <el-tabs tab-position="left" v-model="tabs" @tab-click="tabClick" :stretch="false" style="height: 200px;">
          <el-tab-pane name="test">
            <div slot="label" class="tablabel">
              <i>
                <img src="../../../assets/images/student/bluecs.png" v-if="tabs == 'test'" alt="">
                <img src="../../../assets/images/student/ccccs.png" v-else alt="">
              </i>
              <span>我的测试</span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="我的收藏" name="coins">
            <span slot="label" class="tablabel">
              <i>
                <img src="../../../assets/images/student/bluejb.png" v-if="tabs == 'coins'" alt="">
                <img src="../../../assets/images/student/cccjb.png" v-else alt="">
              </i>
              <span>我的金币</span>
            </span>
          </el-tab-pane>
          <el-tab-pane label="我的订单" name="order">
            <span slot="label" class="tablabel">
              <i>
<!--                <img src="../../../assets/images/student/bluejb.png" v-if="tabs == 'order'" alt="">-->
                <!--                <img src="../../../assets/images/student/cccjb.png" v-else alt="">-->
                 <svg-icon icon="icon12" v-if="tabs == 'order'"/>
                 <svg-icon icon="icon11" v-else/>
              </i>
              <span>我的订单</span>
            </span>
          </el-tab-pane>
        </el-tabs>
      </div>
      <router-view></router-view>
    </div>
    <Footer/>
  </div>
</template>
<script>
export default {
  components: {
    Headers: (resolve) => {
      require(['@/components/student/header.vue'], resolve)
    },
    Footer: (resolve) => {
      require(['@/components/student/footer.vue'], resolve)
    },
  },
  data() {
    return {
      tabs: '1',
      training: null, //select选择器
      timer: [], // 日期选择器
      btnState: 1, // 1是发起兑换，2是查看明细
      dom: null,
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      tableData: [
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
        {index: 1},
      ],
    }
  },
  mounted() {
    this.tabs = this.$route.name
    // this.getData()
  },
  methods: {
    // 瀑布流获取数据
    getData() {
      // 获取需要绑定的table
      let dom = document.querySelector('.table')
      let _this = this
      dom.addEventListener('scroll', function (e) {
        // dom高度减去滚动条滚动距离 // 计算滚动条距离table底部的高度
        let distance = dom.scrollHeight - dom.scrollTop - dom.clientHeight
        // 滚动条到底，请求接口
        if (distance <= 1) {
          // 请求完所有数据，提示
          setTimeout(() => {
            _this.$message.warning('没有更多！')
          }, 1000);
        }
      })
    },
    // tab切换
    tabClick() {
      if (this.tabs == 'coins') {
        this.$router.replace({path: 'coins'})
      } else if (this.tabs == 'test') {
        this.$router.replace({path: 'test'})
      } else if (this.tabs == 'order') {
        this.$router.replace({path: 'order'})
      }
    },
    // 查看按钮/发起兑换
    state() {
      if (this.btnState == 1) {
        this.btnState = 2
      } else {
        this.btnState = 1
      }
    },
  }
}
</script>
<style lang="scss" scoped>
header {
  width: 100%;
  height: 90px;
  box-shadow: 0 2px 4px 2px rgba(89, 144, 255, .1);
}

.personalCenter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .main {
    position: absolute;
    top: 90px;
    left: 0;
    bottom: 50px;
    right: 0;
    box-sizing: border-box;
    padding: 2% 0;

    .tabs {
      height: 100%;
      width: 10.5vw;
      border-radius: 10px;
      box-shadow: 0 0 8px 4px rgba(89, 144, 255, .2);
      float: left;
      box-sizing: border-box;
      padding-top: 2%;
    }
  }
}

.tablabel {
  position: relative;
  display: block;
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  height: 100%;
  // margin: auto;
  i {
    width: 1.1vw;
    height: 1.1vw;
    display: block;
    float: left;
    margin-top: 3px;
    margin-right: .4vw;

    img {
      display: inline-block;
      width: 1.1vw;
      height: 1.1vw;
    }
  }

  span {
    display: block;
    float: left;
  }
}
</style>
<style lang="scss">
.personalCenter {
  .el-table {
    overflow: auto;
  }

  .el-tabs__header.is-left {
    width: 10.5vw;

    .el-tabs__active-bar {
      display: none;
    }

    #tab-first {
      font-size: 20px;
      padding: 0;
      // text-align: center;
    }

    .is-active {
      background-color: #d8effb;
    }

    .el-tabs__header.is-left .el-tabs__nav > div {
      width: 3px !important;
    }
  }

  .el-tabs__nav {
    padding-top: 10px;
  }

  .el-tabs__item {
    color: #999;
    height: 3vw;
    line-height: 3vw;
    font-size: 1.1vw;
  }

  .el-tabs__item.is-active {
    color: #409EFF !important;
    border-right: 5px solid #409EFF;
  }

  .el-tabs__item:hover {
    color: #409EFF !important;
  }

  .el-tabs__header.is-left .is-active {
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px 0px rgba(89, 144, 255, 0.3) !important;
  }

  .el-input__inner {
    height: 2vw;
    background: #F1F8FF;
  }

  .el-input__icon {
    line-height: inherit;
    width: 2vw;
  }

  .el-input__suffix-inner {
    display: block;
    height: 100%;
    // padding-top: .3vw;
  }

  .el-range-input {
    background: #F1F8FF;
  }

  .el-date-editor .el-range__icon {
    display: none;
  }
}
</style>